<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('damReport.weekly_average_price_report') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                    <b-row>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Division" vid="division_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="division_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.division') }} <span class="text-danger">*</span>
                                </template>
                                <vue-multi-select
                                  id="division_id"
                                  v-model="values_division"
                                  search
                                  historyButton
                                  :filters="filters_division"
                                  :options="options"
                                  :selectOptions="divisionList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  class="w-100"
                                  :btnLabel="btnLabel"
                                  btnClass="w-100 d-block text-left"
                                  />
                                <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="District" vid="district_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="district_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.district') }} <span class="text-danger">*</span>
                                </template>
                                <vue-multi-select
                                  id="district_id"
                                  v-model="values_district"
                                  search
                                  historyButton
                                  :filters="filters_district"
                                  :options="options"
                                  :selectOptions="districtList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  class="w-100"
                                  :btnLabel="btnLabel"
                                  btnClass="w-100 d-block text-left"
                                  />
                                <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Upazila" vid="upazila_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="upazila_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.upazila') }} <span class="text-danger">*</span>
                                </template>
                                <vue-multi-select
                                id="upazila_id"
                                v-model="values_upazila"
                                search
                                historyButton
                                :filters="filters_upazila"
                                :options="options"
                                :selectOptions="upazilaList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                class="w-100"
                                :btnLabel="btnLabel"
                                btnClass="w-100 d-block text-left"
                                />
                                <div class="invalid-feedback d-block"   v-if="errors.length && clickSubmit">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Market" vid="market_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="market_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('crop_price_config.market') }} <span class="text-danger">*</span>
                                </template>
                                <vue-multi-select
                                id="market_id"
                                v-model="values_market"
                                search
                                historyButton
                                :filters="filters_market"
                                :options="options"
                                :selectOptions="marketList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                class="w-100"
                                :btnLabel="btnLabel"
                                btnClass="w-100 d-block text-left"
                                />
                                <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12">
                      <ValidationProvider name="Commodity Group Name" vid="commodity_group_name" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="commodity_group_name"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('crop_price_config.commodity_group_name') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="search.commodity_group_id"
                            :options="groupList"
                            id="commodity_group_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Commodity Sub Group Id" vid="commodity_sub_group_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="commodity_sub_group_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('crop_price_config.commodity_sub_group') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="search.commodity_sub_group_id"
                                    :options="subgroupList"
                                    id="commodity_sub_group_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <ValidationProvider name="Commodity Name" vid="commodity_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('crop_price_config.commodity_name')"
                                label-for="commodity_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('crop_price_config.commodity_name') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="search.commodity_id"
                                    :options="commodityNameList"
                                    id="commodity_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12">
                      <ValidationProvider name="Year" vid="year" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="year"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('crop_price_config.year') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="search.year"
                                :options="yearList"
                                id="year"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12">
                      <ValidationProvider name="Month From" vid="month_from" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="month_from"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('damReport.month_from') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="search.month_from"
                                :options="monthList"
                                id="month_from"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12">
                      <ValidationProvider name="Month To" vid="month_to" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="month_to"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('damReport.month_to') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="search.month_to"
                                :options="monthList"
                                id="month_to"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2" @click="clickSubmit=true">{{ $t('globalTrans.search') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
          <!-- <b-col md="12"> -->
            <b-overlay :show="loading">
              <iq-card v-if='datas.length'>
              <!-- <iq-card> -->
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('damReport.weekly_average_price_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                            <b-button class="btn_add_new mr-2" @click="pdfExport">
                              <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                            </b-button>
                            <export-excel
                                class="btn btn_add_new"
                                :data="dataCustomize"
                                :fields= "json_fields"
                                :title="headerValue"
                                name="weekly-average-price-report.xls"
                                :default-value="headerValue">
                                <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                            </export-excel>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="warehouseServiceBaseUrl" uri="/master-warehouse-report-heading/detail" :org-id="13">
                                         <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                        {{ $t('damReport.weekly_average_price_report') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <div class="text-center">
                                        <table style="width:100%;color:black;">
                                          <tr>
                                            <td class="align-top" align="right" width="20%">{{ $t('globalTrans.division') }}</td>
                                            <td class="align-top" align="center" width="5%">:</td>
                                            <td class="align-top" align="left" width="75%" >{{ getDivisionName(search.division_id) }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('globalTrans.district') }}</td>
                                            <td class="align-top" align="center" width="5%">:</td>
                                            <td class="align-top" align="left" >{{ getDistrictName(search.district_id) }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('globalTrans.upazila') }}</td>
                                            <td class="align-top" align="center" width="5%">:</td>
                                            <td class="align-top" align="left" >{{ getUpazilaNameCustom(search.upazila_id) }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('crop_price_config.market') }}</td>
                                            <td class="align-top" align="center" width="5%">:</td>
                                            <td class="align-top" align="left" >{{ getMarketName(search.market_id) }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('crop_price_config.commodity_group_name') }}</td>
                                            <td class="align-top" align="center">:</td>
                                            <td class="align-top" align="left" style="width:10%">{{ ($i18n.locale==='bn') ? search.commodity_group_name_bn : search.commodity_group_name_en }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('crop_price_config.commodity_sub_group') }}</td>
                                            <td class="align-top" align="center">:</td>
                                            <td class="align-top" align="left">{{ ($i18n.locale==='bn') ? search.commodity_sub_group_name_bn : search.commodity_sub_group_name_en }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('crop_price_config.commodity_name') }}</td>
                                            <td class="align-top" align="center">:</td>
                                            <td class="align-top" align="left">{{ ($i18n.locale==='bn') ? search.commodity_name_bn : search.commodity_name_en }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('crop_price_config.year') }}</td>
                                            <td class="align-top" align="center">:</td>
                                            <td class="align-top" align="left">{{ ($i18n.locale==='bn') ? search.year_bn : search.year_en }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('damReport.month_from') }}</td>
                                            <td class="align-top" align="center">:</td>
                                            <td class="align-top" align="left">{{ ($i18n.locale==='bn') ? search.month_from_bn : search.month_from_en }}</td>
                                          </tr>
                                          <tr>
                                            <td class="align-top" align="right">{{ $t('damReport.month_to') }}</td>
                                            <td class="align-top" align="center">:</td>
                                            <td class="align-top" align="left">{{ ($i18n.locale==='bn') ? search.month_to_bn : search.month_to_en }}</td>
                                          </tr>
                                        </table>
                                      </div>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col sm="12" class="steaky-header">
                                      <slot>
                                        <b-table head-variant="primary" :sticky-header="stickyHeader" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="datas" :fields="columns">
                                          <template v-slot:cell(a_r_lowestPrice)="data">
                                            {{ parseFloat(data.item.a_r_lowestPrice) || parseFloat(data.item.a_r_howestPrice) ? $n(parseFloat(data.item.a_r_lowestPrice).toFixed(2)) + '-' + $n(parseFloat(data.item.a_r_howestPrice).toFixed(2)) : ' ' }}
                                          </template>
                                          <template v-slot:cell(a_w_lowestPrice)="data">
                                            {{ parseFloat(data.item.a_w_lowestPrice) || parseFloat(data.item.a_w_lowestPrice) ? $n(parseFloat(data.item.a_w_lowestPrice).toFixed(2)) + '-' + $n(parseFloat(data.item.a_w_highestPrice).toFixed(2)) : ' ' }}
                                          </template>
                                          <template v-slot:cell(year)="data">
                                            {{ $n(data.item.year, { useGrouping: false }) }}
                                          </template>
                                        </b-table>
                                      </slot>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
              <div class="panel-body text-center mt-3" v-else>
                <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
              </div>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriMarketingServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import Store from '@/store'
import Vue from 'vue'
import excel from 'vue-excel-export'
import vueMultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'

Vue.use(excel)

const excelColumn = {
    // 'Serial No': 'sl_no',
    Year: 'yr',
    Month: 'month',
    Week: 'week',
    'Product name and description': 'cName',
    Measurement: 'cUName',
    'Average Retail Price': 'wholeSale',
    'Measurement ': 'wUName',
    'Average Wholesale Price': 'awholeSale'
}
const excelColumnBn = {
    // 'ক্রমিক নং': 'sl_no',
    বছর: 'yr',
    মাস: 'month',
    সপ্তাহ: 'week',
    'পণ্যের নাম ও বিবরণ': 'cName',
    একক: 'cUName',
    'গড় খুচরা দর': 'wholeSale',
    'একক ': 'wUName',
    'গড় পাইকারী দর': 'awholeSale'
}

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider,
        ListReportHead,
        vueMultiSelect
    },
    created () {
        // if (this.id) {
        //     const tmp = this.getEditingData()
        //     this.search = tmp
        // }
    },
    data () {
        return {
          btnLabel: (values) => values.length ? this.getNames(values) : this.$t('globalTrans.select'),
          AveragePriceText: '',
          maxPriceMsg: '',
          minPriceMsg: '',
          saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
          search: {
              division_id: [],
              district_id: [],
              upazila_id: [],
              market_id: [],
              commodity_group_id: 0,
              commodity_sub_group_id: 0,
              commodity_id: 0,
              year: 0,
              month_from: 0,
              month_to: 0
          },
            stickyHeader: true,
            headerExcelDefault: {
            orgName: null,
            orgNameBn: null,
            address: null,
            address_bn: null
          },
          currentDate: '',
          listItemofyerterday: '',
          districtList: [],
          upazilaList: [],
          marketList: [],
          subgroupList: [],
          commoditySubGroupList: [],
          commodityNameList: [],
          warehouseServiceBaseUrl: warehouseServiceBaseUrl,
          datas: [],
          showData: false,
          values_division: [],
          values_district: [],
          values_upazila: [],
          values_market: [],
          filters_division: [{
            nameAll: this.$t('globalTrans.select_all'),
            nameNotAll: this.$t('globalTrans.deselect_all'),
            func () {
              return true
            }
          }],
          filters_district: [{
            nameAll: this.$t('globalTrans.select_all'),
            nameNotAll: this.$t('globalTrans.deselect_all'),
            func () {
              return true
            }
          }],
          filters_upazila: [{
            nameAll: this.$t('globalTrans.select_all'),
            nameNotAll: this.$t('globalTrans.deselect_all'),
            func () {
              return true
            }
          }],
          filters_market: [{
            nameAll: this.$t('globalTrans.select_all'),
            nameNotAll: this.$t('globalTrans.deselect_all'),
            func () {
              return true
            }
          }],
          options: {
            multi: true,
            groups: false,
            labelName: 'text',
            labelValue: 'value',
            cssSelected: option => (option.selected ? { 'background-color': '#5764c6' } : '')
          },
          clickSubmit: false
        }
    },
    mounted () {
        core.index()
        flatpickr('.datepicker', {})
        this.AveragePriceText = (parseInt(this.search.HighestPrice) + parseInt(this.search.LowestPrice)) / 2
    },
    computed: {
      headerValue: function () {
        const headerVal = []
        if (this.$i18n.locale === 'en') {
          headerVal[0] = this.headerExcelDefault.orgName
          headerVal[1] = this.headerExcelDefault.address
          headerVal[2] = this.$t('damReport.weekly_average_price_report')
          headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.getDivisionName(this.search.division_id)
          headerVal[4] = this.$t('globalTrans.district') + ': ' + this.getDistrictName(this.search.district_id)
          headerVal[5] = this.$t('globalTrans.upazila') + ':  ' + this.getUpazilaNameCustom(this.search.upazila_id)
          headerVal[6] = this.$t('crop_price_config.market') + ': ' + this.getMarketName(this.search.market_id)
          headerVal[7] = this.$t('crop_price_config.commodity_group') + ':  ' + this.search.commodity_group_name_en
          headerVal[8] = this.$t('crop_price_config.commodity_sub_group') + ': ' + this.search.commodity_sub_group_name_en
          headerVal[9] = this.$t('crop_price_config.commodity_name') + ' : ' + this.search.commodity_name_en
          headerVal[10] = this.$t('crop_price_config.year') + ': ' + this.search.year_en
          headerVal[11] = this.$t('damReport.month_from') + ' : ' + this.search.month_from_en
          headerVal[12] = this.$t('damReport.month_to') + ':  ' + this.search.month_to_en
        } else {
          headerVal[0] = this.headerExcelDefault.orgNameBn
          headerVal[1] = this.headerExcelDefault.address_bn
          headerVal[2] = this.$t('damReport.weekly_average_price_report')
          headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.getDivisionName(this.search.division_id)
          headerVal[4] = this.$t('globalTrans.district') + ': ' + this.getDistrictName(this.search.district_id)
          headerVal[5] = this.$t('globalTrans.upazila') + ':  ' + this.getUpazilaNameCustom(this.search.upazila_id)
          headerVal[6] = this.$t('crop_price_config.market') + ': ' + this.getMarketName(this.search.market_id)
          headerVal[7] = this.$t('crop_price_config.commodity_group') + ':  ' + this.search.commodity_group_name_bn
          headerVal[8] = this.$t('crop_price_config.commodity_sub_group') + ': ' + this.search.commodity_sub_group_name_bn
          headerVal[9] = this.$t('crop_price_config.commodity_name') + ' : ' + this.search.commodity_name_bn
          headerVal[10] = this.$t('crop_price_config.year') + ': ' + this.search.year_bn
          headerVal[11] = this.$t('damReport.month_from') + ' : ' + this.search.month_from_bn
          headerVal[12] = this.$t('damReport.month_to') + ':  ' + this.search.month_to_bn
        }
        return headerVal
      },
        json_fields: function () {
            return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
        },
        dataCustomize () {
            try {
                if (typeof this.datas !== 'undefined') {
                    const listData = this.datas
                    // var slNo = 1
                    listData.map(item => {
                        if (this.$i18n.locale === 'bn') {
                            // item.sl_no = this.$n(slNo++)
                            item.yr = this.$n(item.year, { useGrouping: false })
                            item.month = item.month_name_bn
                            item.week = this.$n(item.week_id, { useGrouping: false })
                            item.cName = item.commodity_name_bn
                            item.cUName = item.unit_name_bn
                            item.wholeSale = parseFloat(item.a_r_lowestPrice) || parseFloat(item.a_r_howestPrice) ? this.$n(parseFloat(item.a_r_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(item.a_r_howestPrice).toFixed(2)) : ' '
                            item.wUName = item.w_unit_name_bn
                            item.awholeSale = parseFloat(item.a_w_lowestPrice) || parseFloat(item.a_w_highestPrice) ? this.$n(parseFloat(item.a_w_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(item.a_w_highestPrice).toFixed(2)) : ' '
                        } else {
                            // item.sl_no = slNo++
                            item.yr = item.year
                            item.month = item.month_name
                            item.week = item.week_id
                            item.cName = item.commodity_name
                            item.cUName = item.unit_name
                            item.wholeSale = parseFloat(item.a_r_lowestPrice).toFixed(2) + '--' + parseFloat(item.a_r_howestPrice).toFixed(2)
                            item.wUName = item.w_unit_name
                            item.awholeSale = parseFloat(item.a_w_lowestPrice).toFixed(2) + '--' + parseFloat(item.a_w_highestPrice).toFixed(2)
                        }
                        return Object.assign({}, item)
                    })
                    return listData
                } else {
                    return []
                }
            } catch (e) {
                return []
            }
        },
        monthList: function () {
            return this.$store.state.Portal.agriObj.monthList
        },
        weekList: function () {
          return this.$store.state.Portal.agriObj.weekList
        },
        divisionList: function () {
          return this.$store.state.Portal.agriObj.divisionList
        },
        groupList () {
          return this.$store.state.Portal.agriObj.commodityGroupList.filter(item => item.status === 1)
        },
        yearList () {
          const yearList = this.$store.state.Portal.agriObj.yearList
          return yearList.map(item => {
            if (this.$i18n.locale === 'en') {
              return { value: item.value, text: item.text_en }
            } else {
              return { value: item.value, text: item.text_bn }
            }
          })
        },
        priceEntryTypeList: function () {
          return this.$store.state.Portal.agriObj.priceEntryTypeList
        },
        timePreiodList: function () {
          return this.$store.state.Portal.agriObj.timePreiodList
        },
        i18 () {
          return this.$i18n.locale
        },
        priceTypeList: function () {
          return this.$store.state.Portal.agriObj.priceTypeList
        },
        columns () {
          const labels = [
              { label: this.$t('globalTrans.year'), class: 'text-center', sortable: true },
              { label: this.$t('crop_price_config.month'), class: 'text-center', sortable: true },
              { label: this.$t('crop_price_config.week'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.product_name_and_description'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.measurement'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.average_retail_price'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.measurement'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.average_wholesale_price'), class: 'text-center', sortable: true }
            ]
          let keys = []

          if (this.$i18n.locale === 'bn') {
            keys = [
              { key: 'year' },
              { key: 'month_name_bn' },
              { key: 'week_id' },
              { key: 'commodity_name_bn' },
              { key: 'unit_name_bn' },
              { key: 'a_r_lowestPrice' },
              { key: 'w_unit_name_bn' },
              { key: 'a_w_lowestPrice' }
            ]
          } else {
            keys = [
              { key: 'year' },
              { key: 'month_name' },
              { key: 'week_id' },
              { key: 'commodity_name' },
              { key: 'unit_name' },
              { key: 'a_r_lowestPrice' },
              { key: 'w_unit_name' },
              { key: 'a_w_lowestPrice' }
            ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
        },
        loading () {
          return this.$store.state.commonObj.loading
        }
    },
    watch: {
        values_division: function (newVal) {
          if (newVal.length) {
            this.districtList = this.getDistrictList(newVal)
          } else {
            this.districtList = []
          }
        },
        values_district: function (newVal) {
          if (newVal.length) {
            this.upazilaList = this.getUpazilaList(newVal)
          } else {
            this.upazilaList = []
          }
        },
        values_upazila: function (newVal) {
          if (newVal.length) {
            this.marketList = this.getMarketList(newVal)
          } else {
            this.marketList = []
          }
        },
        'search.commodity_group_id': function (newVal, oldVal) {
            this.subgroupList = this.getSubGroupList(newVal)
        },
        'search.commodity_sub_group_id': function (newVal, oldVal) {
            this.commodityNameList = this.getCommodityNameList(newVal)
        }
    },
    methods: {
      pdfExport () {
          const reportTitle = this.$t('damReport.weekly_average_price_report')
          ExportPdf.exportPdfDetails(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', 13, reportTitle, this.datas, this, this.search)
        },
        onChangeFile (e) {
            this.attachmentDemo = e.target.files[0]
        },
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail/13').then(response => {
            if (response.success) {
              const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 13)
              const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
              const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
              this.headerExcelDefault.orgName = orgName
              this.headerExcelDefault.orgNameBn = orgNameBn
              this.headerExcelDefault.address = response.data.address
              this.headerExcelDefault.address_bn = response.data.address_bn
            }
          })
          Store.commit('mutateCommonProperties', {
            loading: true
          })
          this.showData = true

          const commodityGroupListObj = this.$store.state.Portal.agriObj.commodityGroupList.find(item => item.value === this.search.commodity_group_id)
          this.search.commodity_group_name_en = typeof commodityGroupListObj !== 'undefined' ? commodityGroupListObj.text_en : ''
          this.search.commodity_group_name_bn = typeof commodityGroupListObj !== 'undefined' ? commodityGroupListObj.text_bn : ''

          const commoditySubGroupListObj = this.$store.state.Portal.agriObj.commoditySubGroupList.find(item => item.value === this.search.commodity_sub_group_id)
          this.search.commodity_sub_group_name_en = typeof commoditySubGroupListObj !== 'undefined' ? commoditySubGroupListObj.text_en : ''
          this.search.commodity_sub_group_name_bn = typeof commoditySubGroupListObj !== 'undefined' ? commoditySubGroupListObj.text_bn : ''

          const commodityNameListObj = this.$store.state.Portal.agriObj.commodityNameList.find(item => item.value === this.search.commodity_id)
          this.search.commodity_name_en = typeof commodityNameListObj !== 'undefined' ? commodityNameListObj.text_en : ''
          this.search.commodity_name_bn = typeof commodityNameListObj !== 'undefined' ? commodityNameListObj.text_bn : ''

          const yearListObj = this.$store.state.Portal.agriObj.yearList.find(item => item.value === this.search.year)
          this.search.year_en = typeof yearListObj !== 'undefined' ? yearListObj.text_en : ''
          this.search.year_bn = typeof yearListObj !== 'undefined' ? yearListObj.text_bn : ''

          const monthFromListObj = this.$store.state.commonObj.monthList.find(item => item.value === this.search.month_from)
          this.search.month_from_en = typeof monthFromListObj !== 'undefined' ? monthFromListObj.text_en : ''
          this.search.month_from_bn = typeof monthFromListObj !== 'undefined' ? monthFromListObj.text_bn : ''

          const monthToListObj = this.$store.state.commonObj.monthList.find(item => item.value === this.search.month_to)
          this.search.month_to_en = typeof monthToListObj !== 'undefined' ? monthToListObj.text_en : ''
          this.search.month_to_bn = typeof monthToListObj !== 'undefined' ? monthToListObj.text_bn : ''

          const marketIds = []
          this.values_market.forEach(item => {
            marketIds.push(parseInt(item.value))
          })
          this.search.market_id = marketIds

          const searchData = {
            division_id: this.search.division_id,
            district_id: this.search.district_id,
            upazila_id: this.search.upazila_id,
            market_id: this.search.market_id,
            commodity_group_id: this.search.commodity_group_id,
            commodity_sub_group_id: this.search.commodity_sub_group_id,
            commodity_id: this.search.commodity_id,
            year: this.search.year,
            month_from: this.search.month_from,
            month_to: this.search.month_to
          }
          const result = await RestApi.postData(agriMarketingServiceBaseUrl, '/crop-price-info/reports/price-report/weekly-average-price-report', searchData)
          if (result.success) {
            Store.commit('mutateCommonProperties', {
              loading: false
            })
            if (result.data) {
              this.datas = this.getRelationalData(result.data)
            }
            this.clickSubmit = false
          } else {
            this.datas = []
            Store.commit('mutateCommonProperties', {
              loading: false
            })
            this.clickSubmit = false
        }
        },
        getRelationalData (data) {
          const commodityNameList = this.$store.state.Portal.agriObj.commodityNameList
          const list = data.map(item => {
            const commodityObj = commodityNameList.find(com => com.value === item.commodity_id)
            const monthObj = this.$store.state.commonObj.monthList.find(month => month.value === item.month_id)
            const rUnitObj = this.$store.state.Portal.agriObj.measurementUnitList.find(unit => unit.value === item.unit_retail)
            const wUnitObj = this.$store.state.Portal.agriObj.measurementUnitList.find(unit => unit.value === item.unit_whole_sale)
            // const weekObj = this.$store.state.Portal.agriObj.weekList.find(year => year.value === item.week_id)
            const commodityData = {
              commodity_name: typeof commodityObj !== 'undefined' ? commodityObj.text_en : '',
              commodity_name_bn: typeof commodityObj !== 'undefined' ? commodityObj.text_bn : ''
            }
            const monthData = {
              month_name: typeof monthObj !== 'undefined' ? monthObj.text_en : '',
              month_name_bn: typeof monthObj !== 'undefined' ? monthObj.text_bn : ''
            }
            const rUnitData = {
              unit_name: typeof rUnitObj !== 'undefined' ? rUnitObj.text_en : '',
              unit_name_bn: typeof rUnitObj !== 'undefined' ? rUnitObj.text_bn : ''
            }
            const wUnitData = {
              w_unit_name: typeof wUnitObj !== 'undefined' ? wUnitObj.text_en : '',
              w_unit_name_bn: typeof wUnitObj !== 'undefined' ? wUnitObj.text_bn : ''
            }
            return Object.assign({}, item, commodityData, monthData, wUnitData, rUnitData)
          })
          return list
        },
        getDivisionName (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const obj = this.$store.state.Portal.agriObj.divisionList.find(item => item.value === parseInt(id))
              arr.push(obj?.text)
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.Portal.agriObj.divisionList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        getDistrictList (divisionList) {
          const divisionIds = []
          divisionList.forEach(item => {
            divisionIds.push(parseInt(item.value))
          })
          this.search.division_id = divisionIds
          this.districtList = this.$store.state.Portal.agriObj.districtList.filter(item => divisionIds.includes(item.division_id)).map(el => {
            return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
          })
          if (this.values_district.length) {
            const arr = []
            this.values_district.forEach(item => {
              const obj = this.districtList.find(obj => obj.value === parseInt(item.value))
              if (obj !== undefined) {
                arr.push(item)
              }
            })
            this.values_district = arr
          }
          return this.districtList
        },
        getDistrictName (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const obj = this.$store.state.Portal.agriObj.districtList.find(item => item.value === parseInt(id))
              arr.push(obj?.text)
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.Portal.agriObj.districtList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        getUpazilaList (districtList) {
          const districtIds = []
          districtList.forEach(item => {
            districtIds.push(parseInt(item.value))
          })
          this.search.district_id = districtIds
          this.upazilaList = this.$store.state.Portal.agriObj.upazilaList.filter(item => districtIds.includes(item.district_id)).map(el => {
            return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
          })

          if (this.values_upazila.length) {
            const arr = []
            this.values_upazila.forEach(item => {
              const obj = this.upazilaList.find(obj => obj.value === parseInt(item.value))
              if (obj !== undefined) {
                arr.push(item)
              }
            })
            this.values_upazila = arr
          }
          return this.upazilaList
        },
        getUpazilaName (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const obj = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === parseInt(id))
              arr.push(obj?.text)
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        getUpazilaNameCustom (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const market = this.$store.state.Portal.agriObj.marketList.find(item => item.upazila_id === parseInt(id))
              if (market !== undefined) {
                const obj = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === parseInt(id))
                arr.push(obj?.text)
              }
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        getMarketList (upazilaList) {
          const upazilaIds = []
          upazilaList.forEach(item => {
            upazilaIds.push(parseInt(item.value))
          })
          this.search.upazila_id = upazilaIds
          this.marketList = this.$store.state.Portal.agriObj.marketList.filter(item => upazilaIds.includes(item.upazila_id)).map(el => {
            return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
          })

          if (this.values_market.length) {
            const arr = []
            this.values_market.forEach(item => {
              const obj = this.upazilaList.find(obj => obj.value === parseInt(item.value))
              if (obj !== undefined) {
                arr.push(item)
              }
            })
            this.values_market = arr
          }
          return this.marketList
        },
        getMarketName (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const obj = this.$store.state.Portal.agriObj.marketList.find(item => item.value === parseInt(id))
              arr.push(obj?.text)
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.Portal.agriObj.marketList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        getNames (list) {
          const arr = []
          list.forEach(item => {
            arr.push(item?.text)
          })
          const str = arr.join(', ')
          return str.length > 50 ? str.substring(0, 40) + '..' : str
        },
        getPriceTypeList: function (entryType) {
            const dataList = this.$store.state.Portal.agriObj.priceTypeList.filter(item => item.entry_type === entryType)
            return dataList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        },
        getSubGroupList (groupId = null) {
            const subgroupList = this.$store.state.Portal.agriObj.commoditySubGroupList.filter(item => item.status === 1)

            if (groupId) {
                return subgroupList.filter(subgroup => subgroup.commodity_group_id === groupId)
            }
            return subgroupList
        },
        getCommodityNameList (groupId = null) {
            const commodityNameList = this.$store.state.Portal.agriObj.commodityNameList.filter(item => item.status === 1)

            if (groupId) {
                return commodityNameList.filter(commodityName => commodityName.commodity_sub_group_id === groupId)
            }
            return commodityNameList
        }
    }
}
</script>
